<template>
  <div id="thc-header">
    <div class="logo-container" @click="goHome"></div>
    <div class="header-text"><h1>Gallery</h1></div>
    <div class="close" @click="goHome">X</div>
  </div>
</template>

<script>
export default {
  name: 'ThcHeader',
  components: {},
  methods: {
    goHome: function () {
      this.$router.push('/');
    }
  },
  computed: {},
}
</script>

<style lang="scss" scoped>
  #thc-header {
    display: flex;
    height: 60px;
    width: 100vw;
    background: linear-gradient(rgba(128, 0, 128, .4), #111);
    // justify-content: space-around;
  }
  .logo-container {
    height: 100%;
    width: 80px;
    background-image: url('../../../assets/img/logo-tiny.png');
    background-size: cover;
    cursor: pointer;
  }
  .header-text {
    font-family: 'Montserrat Alternates', sans-serif;
    color: #ccc;
    align-self: center;
    padding-left: 20px;
  }
  .close {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 1.4em;
    color: #ccc;
    align-self: center;
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .close:hover {
    color: teal;
  }
</style>
