<template>
  <div id="thc-gallery">
    <thc-header></thc-header>
    <div class="panel-wrapper">
      <gallery-panel />
    </div>
  </div>
</template>

<script>
import VueGallery from 'vue-gallery';
import ThcHeader from './home/sections/ThcHeader.vue';
import GalleryPanel from '../components/GalleryPanel.vue';

export default {
  name: 'ThcGallery',
  components: {
    VueGallery,
    ThcHeader,
    GalleryPanel,
  },
}
</script>

<style lang="scss" scoped>
.panel-wrapper {
  padding: 40px 20px 0 20px
}
</style>
